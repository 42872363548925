import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class RequestersName extends Component {
  state = {
    ht: 'error',
    val: undefined,
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length > 0) {
      this.setState({ ht: undefined })
    } else {
      this.setState({ ht: 'error' })
    }
  }

  render() {
    const { onChangeValue } = this.props
    const regex = /^[^*|":<>[\]{}`\\;@$]+$/
    return (
      <AlphanumericInput
        name='requestersName'
        id='Alphanumeric--requestorsName'
        labelVisual="Requester's Name"
        value=''
        highlightType={this.state.ht}
        onChange={function (e) {
          onChangeValue(e)
          this.handleFormChange(e)
        }.bind(this)}
        filter={regex}
      />
    )
  }
}

export default RequestersName
