import React, { Component } from 'react'
import { GridRow, GridCol, FieldGroup } from '@lmig/lmds-react'
import Phone from '../FormComponents/Phone'
import Email from '../FormComponents/Email'
import RequestersName from '../FormComponents/RequestersName'
import './FormGroupCSS/ContactInfo.css'

class ContactInfo extends Component {
  render() {
    const { onChangeHandler } = this.props
    return (
      <FieldGroup id='fieldgroup--contactInfo' disableMargins='top'>
        <GridRow gutters>
          <GridCol base={12}>
            <h4>
              <strong>Contact Information</strong>
            </h4>
          </GridCol>
          <GridCol base={12} md={6}>
            <FieldGroup
              id='fieldgroup--requestersName'
              disableMargins='top'
              messages={[
                {
                  text: 'Required',
                  type: 'error',
                },
              ]}
            />
            <RequestersName onChangeValue={onChangeHandler} />
          </GridCol>
          <GridCol base={12} md={6}>
            <FieldGroup
              id='fieldgroup--phone'
              disableMargins='top'
              messages={[
                {
                  text: 'Required',
                  type: 'error',
                },
              ]}
            />
            <Phone onChangeValue={onChangeHandler} />
          </GridCol>
          <GridCol base={12}>
            <Email onChangeValue={onChangeHandler} />
          </GridCol>
        </GridRow>
      </FieldGroup>
    )
  }
}

export default ContactInfo
