import React, { Component } from 'react'
import { FieldGroup } from '@lmig/lmds-react'
import './FormComponentsCSS/AdditionalComments.css'

class AdditionalComments extends Component {
  constructor(props) {
    super()
    this.required = props.required
    if (this.required) {
      this.placeholder = 'Please describe what additional information is needed.'
      this.message = [
        {
          text: 'Required',
          type: 'error',
        },
      ]
      this.label = ''
    } else {
      this.placeholder = ''
      this.message = []
      this.label =
        'Please provide any additional information that you would like the Treasury Tax team to know about your request.'
    }
  }

  state = {
    boxState: 'initialBox',
    maxChar: 2000,
    charLeft: 2000,
    value: '',
  }

  componentDidMount() {
    if (this.required) {
      this.setState({ boxState: 'requiredBox' })
    }
  }

  handleMouseOver = () => {
    if (!this.required) {
      this.setState({ boxState: 'mouseOver' })
    }
  }

  handleMouseLeave = () => {
    if (!this.required) {
      this.setState({ boxState: 'initialBox' })
    }
  }

  handleValueChange = event => {
    let input = event.target.value
    let charsLeft = this.state.maxChar - input.length
    // update characters remaining as well a filter out unwanted symbols to protect against attacks
    this.setState({ charLeft: charsLeft, value: event.target.value.replace(/[`~@#$%^*_|+\-="<>]/gi, '') })
  }

  render() {
    const { onChangeHandler } = this.props

    return (
      <FieldGroup
        id='fieldgroup--addComments'
        disableMargins='top'
        labelA11y='Additional comment section, please provide any additional comments you have.'
        messages={this.message}
      >
        <h4>
          <strong>Additional Comments</strong>
        </h4>
        <p>{this.label}</p>
        <textarea
          name='comments'
          id='commentBox'
          className={this.state.boxState}
          placeholder={this.placeholder}
          rows='5'
          cols='75'
          maxLength={this.state.maxChar}
          aria-placeholder='Enter any additional comments.'
          aria-multiline='true'
          value={this.state.value}
          onMouseEnter={this.handleMouseOver}
          onMouseLeave={this.handleMouseLeave}
          onChange={function (e) {
            onChangeHandler(e)
            this.handleValueChange(e)
          }.bind(this)}
        />
        <span>
          {this.state.charLeft}/{this.state.maxChar} characters left.
        </span>
      </FieldGroup>
    )
  }
}

export default AdditionalComments
