import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class TaxID extends Component {
  state = {
    ht: 'error',
    val: undefined,
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length > 0) {
      this.setState({ ht: undefined })
    } else {
      this.setState({ ht: 'error' })
    }
  }
  render() {
    const { onChangeValue } = this.props
    const regex = /^[0-9]{0,4}$/
    return (
      <div>
        <AlphanumericInput
          name='taxID'
          highlightType={this.state.ht}
          id='Alphanumeric--taxID'
          labelVisual='Last 4 Digits of Tax ID'
          onChange={function (e) {
            onChangeValue(e)
            this.handleFormChange(e)
          }.bind(this)}
          filter={regex}
          value=''
        />
      </div>
    )
  }
}

export default TaxID
