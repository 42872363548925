import React, { useEffect } from 'react'
import { useParams } from 'react-router-dom'
import RequestNameTaxID from './Forms/RequestNameTaxID'
import RequestAddInfo from './Forms/RequestAddInfo'
import RequestAddrChange from './Forms/RequestAddrChange'
import Request1099 from './Forms/Request1099'
import ReactGA from 'react-ga'
import NotFound from '../common/NotFound'

function DataForm() {
  let { id } = useParams()

  useEffect(() => {
    ReactGA.initialize('UA-187150487-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  function renderSelection() {
    const componentDictionary = {
      1: <RequestNameTaxID />,
      2: <RequestAddInfo />,
      3: <RequestAddrChange />,
      4: <Request1099 />,
    }
    if (!componentDictionary?.[id]) {
      return <NotFound />
    }
    return componentDictionary[id]
  }

  return renderSelection()
}

export default DataForm
