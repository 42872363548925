import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class Email extends Component {
  render() {
    const { onChangeValue } = this.props
    return (
      <AlphanumericInput
        name='email'
        id='email input'
        labelVisual='Email'
        type='email'
        value=''
        onChange={onChangeValue}
      />
    )
  }
}

export default Email
