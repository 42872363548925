import React, { Component } from 'react'
import axios from 'axios'
import { Button, Form, FieldGroup, Notification, Heading, LoadingSpinner } from '@lmig/lmds-react'
import Information1099 from './FormGroup/1099Information'
import ContactInfo from './FormGroup/ContactInfo'
import AdditionalComments from './FormComponents/AdditionalComments'
import SupportingDocs from './FormGroup/SupportingDocs'
import Error from './FormError/Error'
import './FormCSS/Form.css'

class RequestAddInfo extends Component {
  state = {
    customerTicketNumber: '',
    hasInvalidFields: false,
    missingFields: [],
    attachmentList: [],
    isError: false,
    isLoading: false,
    loadingStatus: '',
    requestAddInfo: {
      trackingNum: '',
      recipientName: '',
      additionalRecipients: '',
      requestersName: '',
      phoneNumber: '',
      email: '',
      comments: '',
    },
  }

  // handles all state changes outside of the additional recipients and attachments
  onChangeValueHandler = val => {
    let inputName = val.target.name
    let inputVal = val.target.value
    let requestState = this.state.requestAddInfo
    if (requestState.hasOwnProperty(inputName)) {
      requestState[inputName] = inputVal
      this.setState({ requestAddInfo: requestState })
    }
  }

  // handles Additional Recipient state change
  onChangeAdditionalRecipient = val => {
    let requestState = this.state.requestAddInfo
    requestState['additionalRecipients'] = val.toString()
    this.setState({ requestAddInfo: requestState })
  }

  // handles submit, checks if required fields are empty before populating tickets
  handleSubmit = () => {
    this.validateAllFields()
    if (this.state.missingFields.length !== 0) {
      this.setState({ hasInvalidFields: true })
      window.scroll(0, 0)
    } else {
      this.populateTicket()
      this.setState({ hasInvalidFields: false })
    }
  }

  // validates which fields need to be addressed
  validateAllFields() {
    this.setState({ hasInvalidFields: false })
    this.state.missingFields = []
    if (this.state.requestAddInfo.recipientName === '') {
      this.state.missingFields.push('Recipient name needed.')
    }
    if (this.state.requestAddInfo.requestersName === '') {
      this.state.missingFields.push('Requester name is needed.')
    }
    if (this.state.requestAddInfo.phoneNumber === '') {
      this.state.missingFields.push('Telephone number is needed.')
    }
    if (this.state.requestAddInfo.phoneNumber !== '' && this.state.requestAddInfo.phoneNumber.length !== 14) {
      this.state.missingFields.push('Phone number in incomplete.')
    }
    if (this.state.requestAddInfo.email !== '' && this.isValidEmail(this.state.requestAddInfo.email) === false) {
      this.state.missingFields.push('Please enter a valid email with @ symbol.')
    }
    if (this.state.requestAddInfo.comments === '') {
      this.state.missingFields.push('Additional comment is needed.')
    }
  }

  isValidEmail(emailPassed) {
    const re = /\S+@\S+\.\S+/
    return re.test(emailPassed)
  }

  // this will create a ticket and upload any attachments if there are any
  populateTicket() {
    let postUrl = '/api/createJiraTicket/RAI'
    const formData = new FormData()
    formData.append('requestData', JSON.stringify(this.state.requestAddInfo))
    // add files to data form
    this.state.attachmentList.map(aFile => formData.append('files', aFile))
    this.setState({ isLoading: true, loadingStatus: 'Creating request...' })
    axios({
      method: 'post',
      url: postUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(result => {
        // if email sent
        if (result.status === 201) {
          this.setState({ isLoading: false, loadingStatus: '' })
          window.open('/confirmationPage/1', '_self')
        } else {
          // error occurred that isn't a network error
          this.setState({ isLoading: false, loadingStatus: '', isError: true })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, loadingStatus: '', isError: true })
        console.error('error: ', error)
      })
  }

  // adds attachments to list
  handleAttachments = filesToPass => {
    this.setState({ attachmentList: filesToPass })
  }

  // handle state change from the closeModal function in Error.js
  handleErrorPopup = isOpen => {
    this.setState({ isError: isOpen })
  }

  render() {
    let listOfMVs = this.state.missingFields.map((v, index) => (
      <li className='notification-bullet' key={index}>
        {v}
      </li>
    ))
    return (
      <>
        {this.state.isError && <Error isError={this.state.isError} update={this.handleErrorPopup} />}
        {this.state.isLoading && <LoadingSpinner />}
        <Form>
          <Heading type={'h3-light'}>Request Additional Information</Heading>
          {this.state.hasInvalidFields && (
            <Notification className='notification-window' type='error'>
              <h6>Please address the following issues:</h6>
              <br />
              <ul>{listOfMVs}</ul>
            </Notification>
          )}
          <br />
          <FieldGroup id='fieldgroup--differentInquiryType' disableMargins='top'>
            <div className='formBorder'>
              <div className='borderMargin'>
                <Information1099
                  additionalRecChange={this.onChangeAdditionalRecipient}
                  onChangeHandler={this.onChangeValueHandler}
                />
                <br />
                <AdditionalComments onChangeHandler={this.onChangeValueHandler} required />
                <br />
                <SupportingDocs
                  attachmentsList={this.handleAttachments}
                  required={false}
                  canComment={false}
                  optional={''}
                  W9Wording={''}
                  maxFileSize={'20MB'}
                  maxTotalFileSize={'400MB'}
                />
                <br />
                <ContactInfo onChangeHandler={this.onChangeValueHandler} />
                <br />
                <br />
                <Button variant='primary' size='large' onClick={this.handleSubmit} disabled={this.state.isLoading}>
                  {this.state.isLoading ? this.state.loadingStatus : 'Submit Request'}
                </Button>
              </div>
            </div>
          </FieldGroup>
        </Form>
      </>
    )
  }
}

export default RequestAddInfo
