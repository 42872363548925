import React, { useEffect } from 'react'
import { BodyText, Button, Card, FieldGroup, GridCol, GridRow, Heading, IconSuccessAlert, Link } from '@lmig/lmds-react'
import ReactGA from 'react-ga'
import { useParams } from 'react-router-dom'

function RenderPageBody({ confirmationSelection, ticketNumber }) {
  let selection = parseInt(confirmationSelection)

  const requestVerbiage = {
    verbiageOptions: [
      {
        requestTitle: 'Request a Name or Tax ID Change',
        requestDesc: (
            <p id='message-lm-med-teal' className='center-text'>
              Thank you for your request to update your name or tax ID. Please allow up to <strong>2-4 weeks</strong>{' '}
              for processing.
            </p>
        ),
        additionalInfo: (
            <p id='message-lm-med-teal' className='center-text'>
              To look up the status of your request, <Link href='/viewticket'>Click here</Link> and enter your ticket
              number: {ticketNumber}
            </p>
        ),
      },
      {
        requestTitle: 'Request additional information',
        requestDesc: (
            <p id='message-lm-med-teal' className='center-text'>
              Thank you for your request to stop pay your current outstanding check. Please allow up to{' '}
              <strong>6-8 weeks</strong> for review of any changes needed and/or reissuance of this payment
            </p>
        ),
        additionalInfo: (
            <p id='message-lm-med-teal' className='center-text'>
              To look up the status of your request, <Link href='/viewticket'>Click here</Link> and enter your ticket
              number: {ticketNumber}
            </p>
        ),
      },
      {
        requestTitle: 'Request an Address Change or 1099 Reprint',
        requestDesc: (
            <p id='message-lm-med-teal' className='center-text'>
                Thank you for your request to update your address or reprint your 1099. Please allow up to <strong>2-4 weeks</strong>{' '} for processing.
            </p>
        ),
          additionalInfo: (
              <p id='message-lm-med-teal' className='center-text'>
                  To look up the status of your request, <Link href='/viewticket'>Click here</Link> and enter your ticket
                  number: {ticketNumber}
              </p>
          ),
      },
      {
        requestTitle: 'Request a 1099 (I did not receive my 1099)',
        requestDesc: (
            <p id='message-lm-med-teal' className='center-text'>
              Thank you for your request to update your address or reprint your 1099. Please allow up to <strong>2-4 weeks</strong>{' '} for processing.
            </p>
        ),
        additionalInfo: (
            <p id='message-lm-med-teal' className='center-text'>
              To look up the status of your request, <Link href='/viewticket'>Click here</Link> and enter your ticket
              number: {ticketNumber}
            </p>
        ),
      },
    ],
  }

  return (
      <div style={{ margin: '60px 0' }}>
        <Heading type={'h3-light'}>{requestVerbiage?.verbiageOptions?.[+selection]?.requestTitle}</Heading>
        <GridRow gutters>
          <GridCol base={12} md={1}>
            <div>
              <IconSuccessAlert className='checkmark' title='Success' color='success' size='48' />
            </div>
          </GridCol>
          <GridCol base={12} md={11}>
            {requestVerbiage?.verbiageOptions?.[selection]?.requestDesc}
          </GridCol>
        </GridRow>
        <GridRow gutters>
          <GridCol>{requestVerbiage?.verbiageOptions?.[selection]?.additionalInfo}</GridCol>
        </GridRow>
      </div>
  )
}

function ConfirmationPage() {
  let { id, ticketNum } = useParams()

  useEffect(() => {
    ReactGA.initialize('UA-182576109-1')
    ReactGA.pageview(window.location.pathname + window.location.search)
  }, [])

  return (
      <>
        <Card className='conf-border'>
          <RenderPageBody confirmationSelection={id} ticketNumber={ticketNum} />
          <hr />
          <p>
            <em>If you are finished you can close this tab.</em>
          </p>
          <GridRow className='center-buttons' vAlign={'bottom'} gutters justify={'end'} style={{ maxWidth: '768px' }}>
            <GridCol base={12} md={6}>
              <FieldGroup labelVisual={'Have another request?'}>
                <Button variant='primary' href='/' size='medium'>
                  Initiate Additional Request
                </Button>
              </FieldGroup>
            </GridCol>
            <GridCol base={12} md={6}>
              <FieldGroup labelVisual={'Need to shop for insurance?'}>
                <Button variant='primary' href='https://www.libertymutual.com/' size='medium'>
                  Liberty Mutual Homepage
                </Button>
              </FieldGroup>
            </GridCol>
          </GridRow>
        </Card>
        <BodyText type={'article-medium'} style={{ marginBottom: '60px' }}>
          Did you enjoy your experience? Let us know by submitting your{' '}
          <Link href='https://www.surveyanalytics.com/t/AJA0dZOrhR' target='_blank'>
            feedback
          </Link>
          !
        </BodyText>
      </>
  )
}

export default ConfirmationPage
