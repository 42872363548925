import React, { Component } from 'react'
import { Button, AlphanumericInput, GridRow, GridCol } from '@lmig/lmds-react'
import './FormComponentsCSS/RecipientName.css'
import NamesOfPayees from './NamesOfPayees'

class AdditionalRecipients extends Component {
  constructor(props) {
    super(props)

    this.state = {
      value: '',
      names: [],
    }
  }

  // handles change in text box
  handleChange = e => {
    let name = e.target.value
    this.setState({ value: name })
  }

  // adds name to names array in this state, also sends it up to the parent (1099Information.js)
  handleAddName = () => {
    let name = ' ' + this.state.value
    if (name !== '') {
      this.setState(state => {
        const names = state.names.concat(name)
        // console.log("Sending name array: " + names);
        this.props.onChangeValue(names)
        return {
          names,
          value: '',
        }
      })
    }
  }

  // deletes name in the names array in this state, also sends up that update to the parent (1099Information.js)
  handleDeleteName = i => {
    this.setState(state => {
      const names = state.names.filter((item, j) => i !== j)
      // console.log("Send delete name: " + names);
      this.props.onChangeValue(names)
      return {
        names,
      }
    })
  }

  // maps the names in the names array to component to be displayed
  renderNames = () => {
    return this.state.names.map((n, index) => (
      <NamesOfPayees key={n} name={n} index={index} onDelete={this.handleDeleteName} />
    ))
  }

  render() {
    const regex = /^[^*|":<>[\]{}`\\;@$%!]+$/
    return (
      <GridRow gutters>
        <GridCol base={12}>
          <span>Provide any additional recipient(s) or DBA below.</span>
        </GridCol>
        <GridCol base={12} md={6}>
          <GridCol flex-auto='true'>
            <AlphanumericInput
              name='additionalRecipients'
              id='Alphanumeric--additionalRecipients'
              labelVisual='Recipient Name'
              value={this.state.value}
              onChange={function (e) {
                this.handleChange(e)
              }.bind(this)}
              filter={regex}
            />
          </GridCol>
        </GridCol>
        <GridCol base={12} md={6}>
          <Button variant='primary' id='recipientBtn' onClick={this.handleAddName}>
            Add a Recipient
          </Button>
        </GridCol>
        <GridRow>
          <GridCol base={12}>
            <div id='recipient-name-spacing'>{this.state.names.length > 0 && this.renderNames()}</div>
          </GridCol>
        </GridRow>
      </GridRow>
    )
  }
}

export default AdditionalRecipients
