import { FilePond, registerPlugin } from 'react-filepond'
import 'filepond/dist/filepond.min.css'
import './FormComponentsCSS/Attachment.css'
import React, { Component } from 'react'
import { FieldGroup } from '@lmig/lmds-react'
import FilePondPluginFileValidateType from 'filepond-plugin-file-validate-type'
import FilePondPluginFileValidateSize from 'filepond-plugin-file-validate-size'

registerPlugin(FilePondPluginFileValidateSize, FilePondPluginFileValidateType)

/* WHEN CHANGING FILE SIZE MAXIMUMS, MAKE SURE TO CHANGE THE LIMITS IN THE PROPERTIES FOLDER FOR THE SPRING API */
class Attachment extends Component {
  constructor(props) {
    super(props)
    this.required = props.required
    this.state = {
      files: [],
      supportedTypes: ['.png', '.jpg', '.jpeg', '.heic', '.heif', '.pdf', '.txt', '.html'],
      errMsg: [],
      maxFiles: 20,
    }
  }

  componentDidMount() {
    if (this.props.required) {
      this.setState({
        errMsg: [
          {
            text: 'W9 is Required',
            type: 'error',
          },
        ],
      })
    }
  }

  checkFiles(files) {
    // iterates through the list of files and checks their file extensions to see if they're valid
    // does this because it needs to add it to the state in order for the FilePond component to work.
    let filenames = []
    for (let i = 0; i < files.length; i++) {
      let tmpFilenameExt = '.' + files[i].fileExtension.toLowerCase()
      if (!this.state.supportedTypes.includes(tmpFilenameExt)) {
        return false
      } else {
        // checks for duplicate file names
        if (filenames.includes(files[i].filename)) {
          return false
        } else {
          filenames.push(files[i].filename)
        }
      }
    }
    return true
  }

  handleUpdateFiles(files) {
    // checks if there are files, if not it sets it as an empty state
    if (files.length !== 0) {
      // checks extensions to make sure they're valid, and if theres no duplicates
      // & adds them to the state if they pass
      // else it will throw an error message saying that there were duplicates.
      if (this.checkFiles(files)) {
        this.setState({
          files: files.map(fileItem => fileItem.file),
        })
        this.props.attachList(this.state.files)
      } else {
        this.setState({
          errMsg: [
            {
              text: 'W9 is Required',
              type: 'error',
            },
            {
              text: 'Duplicate files detected, this new file has been automatically removed.',
              type: 'error',
            },
          ],
        })
      }
    } else {
      if (this.props.required) {
        this.setState({
          files: [],
          errMsg: [
            {
              text: 'W9 is Required',
              type: 'error',
            },
          ],
        })
        this.props.attachList(this.state.files)
      } else {
        this.setState({ files: [], errMsg: [] })
        this.props.attachList(this.state.files)
      }
    }
  }

  render() {
    const { maxFileSize, maxTotalFileSize } = this.props
    return (
      <div className='Filepond'>
        <span>
          <strong>
            {'Supports up to ' + this.state.maxFiles + ' files with the max size of ' + maxFileSize + ' each.'}
          </strong>
        </span>
        <br />
        <span>
          <strong>{'Supported file types: '}</strong>.png, .jpg, .heic, .pdf, .txt, .html
        </span>
        <div className='Filepond-Component'>
          <FieldGroup
            id='fieldgroup--default'
            labelA11y='Add or drop attachments below.'
            disableMargins={'all'}
            messages={this.state.errMsg}
          />
          <FilePond
            class='.filepond--file'
            ref={ref => (this.pond = ref)}
            files={this.state.files}
            // first step of validation/error detection
            fileValidateTypeDetectType={(source, type) =>
              new Promise((resolve, reject) => {
                // checks to see if file name includes selected extensions
                // if it does, it resolves (accepts) the type
                // if not, it will reject it
                if (source.name.includes('.msg')) {
                  type = 'application/vnd.ms-outlook'
                  resolve(type)
                } else if (source.name.includes('.heic')) {
                  type = 'image/heic'
                  resolve(type)
                } else if (source.name.includes('.heif')) {
                  type = 'image/heif'
                  resolve(type)
                } else if (
                  source.name.includes('.png') ||
                  source.name.includes('.jpg') ||
                  source.name.includes('.jpeg') ||
                  source.name.includes('.heic') ||
                  source.name.includes('.heif') ||
                  source.name.includes('.pdf') ||
                  source.name.includes('.txt') ||
                  source.name.includes('.zip') ||
                  source.name.includes('.html') ||
                  source.name.includes('.PNG') ||
                  source.name.includes('.JPG') ||
                  source.name.includes('.JPEG') ||
                  source.name.includes('.HEIC') ||
                  source.name.includes('.HEIF') ||
                  source.name.includes('.PDF') ||
                  source.name.includes('.TXT') ||
                  source.name.includes('.ZIP') ||
                  source.name.includes('.HTML') ||
                  source.name.includes('.MSG')
                ) {
                  resolve(type)
                } else {
                  reject(type)
                }
              })
            }
            // tells the file browser to limit the files on the browser
            acceptedFileTypes={[
              'image/png',
              'image/jpg',
              'image/jpeg',
              'image/heic',
              ' image/heif',
              'application/pdf',
              'text/plain',
              'application/zip',
              'application/x-zip-compressed',
              'application/vnd.ms-outlook',
              'text/html',
              '.msg',
            ]}
            maxFileSize={maxFileSize}
            maxTotalFileSize={maxTotalFileSize}
            allowFileTypeValidation
            allowMultiple
            maxFiles={this.state.maxFiles}
            instantUpload={false}
            checkValidity
            fileValidateTypeLabelExpectedTypes={'See acceptable file types above'}
            onupdatefiles={fileItems => {
              this.handleUpdateFiles(fileItems)
            }}
          />
        </div>
      </div>
    )
  }
}

export default Attachment
