import React, { Component } from 'react'
import { AlphanumericInput, FieldGroup } from '@lmig/lmds-react'

class Zipcode extends Component {
  state = {
    ht: 'error',
    val: undefined,
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length > 0) {
      this.setState({ ht: undefined })
    } else {
      this.setState({ ht: 'error' })
    }
  }

  render() {
    const { onChangeValue } = this.props
    return (
      <FieldGroup
        id='fieldgroup--zipcode'
        disableMargins='top'
        messages={[
          {
            text: 'Required',
            type: 'error',
          },
        ]}
      >
        <AlphanumericInput
          name='zipcode'
          highlightType={this.state.ht}
          id='Alphanumeric--zipcode'
          labelVisual='Zipcode'
          onChange={function (e) {
            onChangeValue(e)
            this.handleFormChange(e)
          }.bind(this)}
          mask='zipCode'
          value=''
        />
      </FieldGroup>
    )
  }
}

export default Zipcode
