import React, { Component } from 'react'
import { Card, CardGrid, Heading, Link } from '@lmig/lmds-react'

class RequestTypeGrid extends Component {
  render() {
    return (
        <div style={{marginTop: '5px'}} className={'request-type-grid'}>
          <Heading type={'h3-light'}>Looking to Submit a Request?</Heading>
          <CardGrid>
            <Card
                style={{backgroundColor: '#1A1446', color: 'white'}}
                actions={[
                  {
                    label: 'Select',
                    variant: 'primary',
                    dynamicWidth: true,
                    onClick: function () {
                      window.open('/form/1')
                    },
                    size: 'medium',
                  },
                ]}
                content='Request a Name or Tax ID Change'
            />
            <Card
                style={{backgroundColor: '#1A1446', color: 'white'}}
                actions={[
                  {
                    label: 'Select',
                    variant: 'primary',
                    dynamicWidth: true,
                    onClick: function () {
                      window.open('/form/2')
                    },
                    size: 'medium',
                  },
                ]}
                content='Request Additional Information'
            />
            <Card
                style={{backgroundColor: '#1A1446', color: 'white'}}
                actions={[
                  {
                    label: 'Select',
                    variant: 'primary',
                    dynamicWidth: true,
                    onClick: function () {
                      window.open('/form/3')
                    },
                    size: 'medium',
                  },
                ]}
                content='Request an Address Change or 1099 Reprint'
            />
            <Card
                style={{backgroundColor: '#1A1446', color: 'white'}}
                actions={[
                  {
                    label: 'Select',
                    variant: 'primary',
                    dynamicWidth: true,
                    onClick: function () {
                      window.open('/form/4')
                    },
                    size: 'medium',
                  },
                ]}
                content='Request a 1099 (I Did Not Receive My 1099)'
            />
            <div>
                <p> Already submitted a request? <Link href='/viewticket'>View ticket status</Link>.</p>
            </div>
          </CardGrid>
        </div>
    )
  }
}

export default RequestTypeGrid
