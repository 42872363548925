export const usStates = [
  // {code: "0", label: "-- Select --"},
  { code: 'AL', label: 'Alabama' },
  { code: 'AK', label: 'Alaska' },
  { code: 'AS', label: 'American Samoa' },
  { code: 'AZ', label: 'Arizona' },
  { code: 'AR', label: 'Arkansas' },
  { code: 'CA', label: 'California' },
  { code: 'CO', label: 'Colorado' },
  { code: 'CT', label: 'Connecticut' },
  { code: 'DE', label: 'Delaware' },
  { code: 'DC', label: 'Dist. of Columbia' },
  { code: 'FL', label: 'Florida' },
  { code: 'GA', label: 'Georgia' },
  { code: 'GU', label: 'Guam' },
  { code: 'HI', label: 'Hawaii' },
  { code: 'ID', label: 'Idaho' },
  { code: 'IL', label: 'Illinois' },
  { code: 'IN', label: 'Indiana' },
  { code: 'IA', label: 'Iowa' },
  { code: 'KS', label: 'Kansas' },
  { code: 'KY', label: 'Kentucky' },
  { code: 'LA', label: 'Louisiana' },
  { code: 'ME', label: 'Maine' },
  { code: 'MD', label: 'Maryland' },
  { code: 'MH', label: 'Marshall Islands' },
  { code: 'MA', label: 'Massachusetts' },
  { code: 'MI', label: 'Michigan' },
  { code: 'MN', label: 'Minnesota' },
  { code: 'MS', label: 'Mississippi' },
  { code: 'MO', label: 'Missouri' },
  { code: 'MT', label: 'Montana' },
  { code: 'NE', label: 'Nebraska' },
  { code: 'NV', label: 'Nevada' },
  { code: 'NH', label: 'New Hampshire' },
  { code: 'NJ', label: 'New Jersey' },
  { code: 'NM', label: 'New Mexico' },
  { code: 'NY', label: 'New York' },
  { code: 'NC', label: 'North Carolina' },
  { code: 'ND', label: 'North Dakota' },
  { code: 'MP', label: 'Northern Mariana Islands' },
  { code: 'OH', label: 'Ohio' },
  { code: 'OK', label: 'Oklahoma' },
  { code: 'OR', label: 'Oregon' },
  { code: 'PA', label: 'Pennsylvania' },
  { code: 'PR', label: 'Puerto Rico' },
  { code: 'RI', label: 'Rhode Island' },
  { code: 'SC', label: 'South Carolina' },
  { code: 'SD', label: 'South Dakota' },
  { code: 'TN', label: 'Tennessee' },
  { code: 'TX', label: 'Texas' },
  { code: 'UT', label: 'Utah' },
  { code: 'VT', label: 'Vermont' },
  { code: 'VA', label: 'Virginia' },
  { code: 'VI', label: 'U.S. Virgin Islands' },
  { code: 'WA', label: 'Washington' },
  { code: 'WV', label: 'West Virginia' },
  { code: 'WI', label: 'Wisconsin' },
  { code: 'WY', label: 'Wyoming' },
]

export const canadaProvinces = [
  { code: '0', label: '-- Select --' },
  { code: 'AB', label: 'Alberta' },
  { code: 'BC', label: 'British Columbia' },
  { code: 'MB', label: 'Monitoba' },
  { code: 'NB', label: 'New Brunswick' },
  { code: 'NL', label: 'Newfoundland and Labrador' },
  { code: 'NT', label: 'Northwest Territories' },
  { code: 'NS', label: 'Nova Scotia' },
  { code: 'NU', label: 'Nunavut' },
  { code: 'ON', label: 'Ontario' },
  { code: 'PE', label: 'Prince Edward Island' },
  { code: 'QC', label: 'Quebec' },
  { code: 'SK', label: 'Saskatchewan' },
  { code: 'YT', label: 'Yukon' },
]

export const countries = [
  //{code: 'Select a country...', label: ''},
  { code: 'United States', label: 'United States' },
  { code: 'Afghanistan', label: 'Afghanistan' },
  { code: 'Albania', label: 'Albania' },
  { code: 'Algeria', label: 'Algeria' },
  { code: 'Andorra', label: 'Andorra' },
  { code: 'Angola', label: 'Angola' },
  { code: 'Anguilla', label: 'Anguilla' },
  { code: 'Antigua And Barbuda', label: 'Antigua And Barbuda' },
  { code: 'Argentina', label: 'Argentina' },
  { code: 'Armenia', label: 'Armenia' },
  { code: 'Aruba', label: 'Aruba' },
  { code: 'Ascension', label: 'Ascension' },
  { code: 'Australia', label: 'Australia' },
  { code: 'Austria', label: 'Austria' },
  { code: 'Azerbaijan', label: 'Azerbaijan' },
  { code: 'Bahamas', label: 'Bahamas' },
  { code: 'Bahrain', label: 'Bahrain' },
  { code: 'Bangladesh', label: 'Bangladesh' },
  { code: 'Barbados', label: 'Barbados' },
  { code: 'Belarus', label: 'Belarus' },
  { code: 'Belgium', label: 'Belgium' },
  { code: 'Belize', label: 'Belize' },
  { code: 'Benin', label: 'Benin' },
  { code: 'Bermuda', label: 'Bermuda' },
  { code: 'Bhutan', label: 'Bhutan' },
  { code: 'Bolivia', label: 'Bolivia' },
  { code: 'Bosnia-Herzegovina', label: 'Bosnia-Herzegovina' },
  { code: 'Botswana', label: 'Botswana' },
  { code: 'Brazil', label: 'Brazil' },
  { code: 'British Virgin Islands', label: 'British Virgin Islands' },
  { code: 'Brunei Darussalam', label: 'Brunei Darussalam' },
  { code: 'Bulgaria', label: 'Bulgaria' },
  { code: 'Burkina Faso', label: 'Burkina Faso' },
  { code: 'Burma', label: 'Burma' },
  { code: 'Burundi', label: 'Burundi' },
  { code: 'Cambodia', label: 'Cambodia' },
  { code: 'Cameroon', label: 'Cameroon' },
  { code: 'Canada', label: 'Canada' },
  { code: 'Cape Verde', label: 'Cape Verde' },
  { code: 'Cayman Islands', label: 'Cayman Islands' },
  { code: 'Central African Republic', label: 'Central African Republic' },
  { code: 'Chad', label: 'Chad' },
  { code: 'Channel Islands', label: 'Channel Islands' },
  { code: 'Chile', label: 'Chile' },
  { code: 'China', label: 'China' },
  { code: 'Colombia', label: 'Colombia' },
  { code: 'Comoros', label: 'Comoros' },
  { code: 'Congo', label: 'Congo' },
  { code: 'Costa Rica', label: 'Costa Rica' },
  { code: 'Cote D’Ivoire', label: 'Cote D’Ivoire' },
  { code: 'Croatia', label: 'Croatia' },
  { code: 'Cuba', label: 'Cuba' },
  { code: 'Curacao', label: 'Curacao' },
  { code: 'Cyprus', label: 'Cyprus' },
  { code: 'Czech Republic', label: 'Czech Republic' },
  { code: 'Denmark', label: 'Denmark' },
  { code: 'Djibouti', label: 'Djibouti' },
  { code: 'Dominica', label: 'Dominica' },
  { code: 'Dominican Republic', label: 'Dominican Republic' },
  { code: 'Ecuador', label: 'Ecuador' },
  { code: 'Egypt', label: 'Egypt' },
  { code: 'El Salvador', label: 'El Salvador' },
  { code: 'England', label: 'England' },
  { code: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { code: 'Eritrea', label: 'Eritrea' },
  { code: 'Estonia', label: 'Estonia' },
  { code: 'Ethiopia', label: 'Ethiopia' },
  { code: 'Falkland Islands', label: 'Falkland Islands' },
  { code: 'Faroe Islands', label: 'Faroe Islands' },
  { code: 'Fiji', label: 'Fiji' },
  { code: 'Finland', label: 'Finland' },
  { code: 'France', label: 'France' },
  { code: 'French Guiana', label: 'French Guiana' },
  { code: 'French Polynesia', label: 'French Polynesia' },
  { code: 'Gabon', label: 'Gabon' },
  { code: 'Gambia', label: 'Gambia' },
  { code: 'Republic Of Georgia', label: 'Republic Of Georgia' },
  { code: 'Germany', label: 'Germany' },
  { code: 'Ghana', label: 'Ghana' },
  { code: 'Gibraltar', label: 'Gibraltar' },
  { code: 'Great Britain', label: 'Great Britain' },
  { code: 'Greece', label: 'Greece' },
  { code: 'Greenland', label: 'Greenland' },
  { code: 'Grenada', label: 'Grenada' },
  { code: 'Guadeloupe', label: 'Guadeloupe' },
  { code: 'Guatemala', label: 'Guatemala' },
  { code: 'Guinea', label: 'Guinea' },
  { code: 'Guinea–Bissau', label: 'Guinea–Bissau' },
  { code: 'Guyana', label: 'Guyana' },
  { code: 'Haiti', label: 'Haiti' },
  { code: 'Honduras', label: 'Honduras' },
  { code: 'Hong Kong', label: 'Hong Kong' },
  { code: 'Hungary', label: 'Hungary' },
  { code: 'Iceland', label: 'Iceland' },
  { code: 'India', label: 'India' },
  { code: 'Indonesia', label: 'Indonesia' },
  { code: 'Iran', label: 'Iran' },
  { code: 'Iraq', label: 'Iraq' },
  { code: 'Ireland', label: 'Ireland' },
  { code: 'Isle Of Man', label: 'Isle Of Man' },
  { code: 'Israel', label: 'Israel' },
  { code: 'Italy', label: 'Italy' },
  { code: 'Jamaica', label: 'Jamaica' },
  { code: 'Japan', label: 'Japan' },
  { code: 'Jordan', label: 'Jordan' },
  { code: 'Kazakhstan', label: 'Kazakhstan' },
  { code: 'Kenya', label: 'Kenya' },
  { code: 'Kiribati', label: 'Kiribati' },
  { code: 'Kuwait', label: 'Kuwait' },
  { code: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { code: 'Laos', label: 'Laos' },
  { code: 'Latvia', label: 'Latvia' },
  { code: 'Lebanon', label: 'Lebanon' },
  { code: 'Lesotho', label: 'Lesotho' },
  { code: 'Liberia', label: 'Liberia' },
  { code: 'Libya', label: 'Libya' },
  { code: 'Liechtenstein', label: 'Liechtenstein' },
  { code: 'Lithuania', label: 'Lithuania' },
  { code: 'Luxembourg', label: 'Luxembourg' },
  { code: 'Macao', label: 'Macao' },
  { code: 'Madagascar', label: 'Madagascar' },
  { code: 'Malawi', label: 'Malawi' },
  { code: 'Malaysia', label: 'Malaysia' },
  { code: 'Maldives', label: 'Maldives' },
  { code: 'Mali', label: 'Mali' },
  { code: 'Malta', label: 'Malta' },
  { code: 'Martinique', label: 'Martinique' },
  { code: 'Mauritania', label: 'Mauritania' },
  { code: 'Mauritius', label: 'Mauritius' },
  { code: 'Mexico', label: 'Mexico' },
  { code: 'Moldova', label: 'Moldova' },
  { code: 'Monaco', label: 'Monaco' },
  { code: 'Mongolia', label: 'Mongolia' },
  { code: 'Montenegro', label: 'Montenegro' },
  { code: 'Montserrat', label: 'Montserrat' },
  { code: 'Morocco', label: 'Morocco' },
  { code: 'Mozambique', label: 'Mozambique' },
  { code: 'Namibia', label: 'Namibia' },
  { code: 'Nauru', label: 'Nauru' },
  { code: 'Nepal', label: 'Nepal' },
  { code: 'Netherlands', label: 'Netherlands' },
  { code: 'New Caledonia', label: 'New Caledonia' },
  { code: 'New Zealand', label: 'New Zealand' },
  { code: 'Nicaragua', label: 'Nicaragua' },
  { code: 'Niger', label: 'Niger' },
  { code: 'Nigeria', label: 'Nigeria' },
  { code: 'Northern Ireland', label: 'Northern Ireland' },
  { code: 'North Korea', label: 'North Korea' },
  { code: 'Norway', label: 'Norway' },
  { code: 'Oman', label: 'Oman' },
  { code: 'Pakistan', label: 'Pakistan' },
  { code: 'Panama', label: 'Panama' },
  { code: 'Papua New Guinea', label: 'Papua New Guinea' },
  { code: 'Paraguay', label: 'Paraguay' },
  { code: 'Peru', label: 'Peru' },
  { code: 'Philippines', label: 'Philippines' },
  { code: 'Pitcairn Island', label: 'Pitcairn Island' },
  { code: 'Poland', label: 'Poland' },
  { code: 'Portugal', label: 'Portugal' },
  { code: 'Puerto Rico', label: 'Puerto Rico' },
  { code: 'Qatar', label: 'Qatar' },
  { code: 'Reunion', label: 'Reunion' },
  { code: 'Republic Of The Congo', label: 'Republic Of The Congo' },
  { code: 'Republic Of Kosovo', label: 'Republic Of Kosovo' },
  { code: 'Republic Of Macedonia', label: 'Republic Of Macedonia' },
  { code: 'Republic Of Serbia', label: 'Republic Of Serbia' },
  { code: 'Republic Of Somaliland', label: 'Republic Of Somaliland' },
  { code: 'Romania', label: 'Romania' },
  { code: 'Russia', label: 'Russia' },
  { code: 'Rwanda', label: 'Rwanda' },
  { code: 'Saint Helena', label: 'Saint Helena' },
  { code: 'Saint Kitts And Nevis', label: 'Saint Kitts And Nevis' },
  { code: 'Saint Lucia', label: 'Saint Lucia' },
  { code: 'Saint Pierre And Miquelon', label: 'Saint Pierre And Miquelon' },
  { code: 'Saint Vincent And Grenadines', label: 'Saint Vincent And Grenadines' },
  { code: 'Samoa', label: 'Samoa' },
  { code: 'San Marino', label: 'San Marino' },
  { code: 'Sao Tome And Principe', label: 'Sao Tome And Principe' },
  { code: 'Saudi Arabia', label: 'Saudi Arabia' },
  { code: 'Scotland', label: 'Scotland' },
  { code: 'Senegal', label: 'Senegal' },
  { code: 'Seychelles', label: 'Seychelles' },
  { code: 'Sierra Leone', label: 'Sierra Leone' },
  { code: 'Singapore', label: 'Singapore' },
  { code: 'Slovenia', label: 'Slovenia' },
  { code: 'Solomon Islands', label: 'Solomon Islands' },
  { code: 'Somalia', label: 'Somalia' },
  { code: 'South Africa', label: 'South Africa' },
  { code: 'South Korea', label: 'South Korea' },
  { code: 'Spain', label: 'Spain' },
  { code: 'Sri Lanka', label: 'Sri Lanka' },
  { code: 'Sudan', label: 'Sudan' },
  { code: 'Suriname', label: 'Suriname' },
  { code: 'Swaziland', label: 'Swaziland' },
  { code: 'Sweden', label: 'Sweden' },
  { code: 'Switzerland', label: 'Switzerland' },
  { code: 'Taiwan', label: 'Taiwan' },
  { code: 'Tajikistan', label: 'Tajikistan' },
  { code: 'Tanzania', label: 'Tanzania' },
  { code: 'Thailand', label: 'Thailand' },
  { code: 'Timor-Leste', label: 'Timor-Leste' },
  { code: 'Togo', label: 'Togo' },
  { code: 'Tonga', label: 'Tonga' },
  { code: 'Trinidad And Tobago', label: 'Trinidad And Tobago' },
  { code: 'Tristan Da Cunha', label: 'Tristan Da Cunha' },
  { code: 'Tunisia', label: 'Tunisia' },
  { code: 'Turkey', label: 'Turkey' },
  { code: 'Turkmenistan', label: 'Turkmenistan' },
  { code: 'Turks And Caicos Islands', label: 'Turks And Caicos Islands' },
  { code: 'Tuvalu', label: 'Tuvalu' },
  { code: 'Uganda', label: 'Uganda' },
  { code: 'Ukraine', label: 'Ukraine' },
  { code: 'United Arab Emirates', label: 'United Arab Emirates' },
  { code: 'United Kingdom', label: 'United Kingdom' },
  { code: 'United States', label: 'United States' },
  { code: 'Unknown', label: 'Unknown' },
  { code: 'Uruguay', label: 'Uruguay' },
  { code: 'US Virgin Islands', label: 'US Virgin Islands' },
  { code: 'Uzbekistan', label: 'Uzbekistan' },
  { code: 'Vanuatu', label: 'Vanuatu' },
  { code: 'Vatican City', label: 'Vatican City' },
  { code: 'Venezuela', label: 'Venezuela' },
  { code: 'Vietnam', label: 'Vietnam' },
  { code: 'Wales', label: 'Wales' },
  { code: 'Wallis And Futuna Islands', label: 'Wallis And Futuna Islands' },
  { code: 'Yemen', label: 'Yemen' },
  { code: 'Zambia', label: 'Zambia' },
  { code: 'Zimbabwe', label: 'Zimbabwe' },
]
