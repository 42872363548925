import React, { Component } from 'react'
import { GridRow, GridCol, FieldGroup } from '@lmig/lmds-react'
import AdditionalRecipients from '../FormComponents/AdditionalRecipients'
import TrackingNumber from '../FormComponents/TrackingNumber'
import RecipientName from '../FormComponents/RecipientName'
import TaxID from '../FormComponents/taxID'

class Information1099 extends Component {
  render() {
    const { additionalRecChange, onChangeHandler, taxIDRequired } = this.props
    return (
      <FieldGroup id='fieldgroup--1099InfoGroup' disableMargins='top'>
        <GridRow gutters>
          <GridCol flex-auto='true'>
            <h4>
              <strong>1099 Information</strong>
            </h4>
            {taxIDRequired && (
              <FieldGroup
                id='fieldgroup--taxID'
                disableMargins='top'
                labelA11y='Required last 4 digits of taxID number'
                messages={[
                  {
                    text: 'Required',
                    type: 'error',
                  },
                ]}
              >
                <TaxID onChangeValue={onChangeHandler} />
              </FieldGroup>
            )}
            {!taxIDRequired && <TrackingNumber onChangeValue={onChangeHandler} />}
            <FieldGroup
              id='fieldgroup--recipientName'
              disableMargins='top'
              labelA11y='Recipient Name as it would appear on 1099'
              messages={[
                {
                  text: 'Required',
                  type: 'error',
                },
              ]}
            >
              <RecipientName onChangeValue={onChangeHandler} />
              <AdditionalRecipients onChangeValue={additionalRecChange} />
            </FieldGroup>
          </GridCol>
        </GridRow>
      </FieldGroup>
    )
  }
}

export default Information1099
