import React, { Component } from 'react'
import { AlphanumericInput } from '@lmig/lmds-react'

class TrackingNumber extends Component {
  render() {
    const { onChangeValue } = this.props
    const regex = /^[a-zA-Z0-9_.-]*$/
    return (
      <div>
        <AlphanumericInput
          name='trackingNum'
          id='Alphanumeric--trackingNum'
          labelVisual='Tracking Number'
          onChange={function (e) {
            onChangeValue(e)
          }.bind(this)}
          filter={regex}
          value=''
        />
      </div>
    )
  }
}

export default TrackingNumber
