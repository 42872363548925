import React, { Component } from 'react'
import axios from 'axios'
import { Button, Form, FieldGroup, Notification, Heading, LoadingSpinner } from '@lmig/lmds-react'
import Information1099 from './FormGroup/1099Information'
import AdditionalComments from './FormComponents/AdditionalComments'
import ContactInfo from './FormGroup/ContactInfo'
import SupportingDocs from './FormGroup/SupportingDocs'
import Error from './FormError/Error'
import './FormCSS/Form.css'

class RequestNameTaxID extends Component {
  state = {
    customerTicketNumber: '',
    hasInvalidFields: false,
    missingFields: [],
    attachmentList: [],
    isError: false,
    isLoading: false,
    loadingStatus: '',
    requestNameTaxID: {
      trackingNum: '',
      recipientName: '',
      additionalRecipients: '',
      requestersName: '',
      phoneNumber: '',
      email: '',
      comments: '',
    },
  }

  // handles all state changes outside of the additional recipients and attachments
  onChangeValueHandler = val => {
    let inputName = val.target.name
    let inputVal = val.target.value
    let requestState = this.state.requestNameTaxID
    if (requestState.hasOwnProperty(inputName)) {
      requestState[inputName] = inputVal
      this.setState({ requestNameTaxID: requestState })
    }
  }

  // handles Additional Recipient state change
  onChangeAdditionalRecipient = val => {
    let requestState = this.state.requestNameTaxID
    requestState['additionalRecipients'] = val.toString()
    this.setState({ requestNameTaxID: requestState })
  }

  // handles submit, checks if required fields are empty before populating tickets
  handleSubmit = () => {
    this.validateAllFields()
    if (this.state.missingFields.length !== 0) {
      this.setState({ hasInvalidFields: true })
      window.scroll(0, 0)
    } else {
      this.populateTicket()
      this.setState({ hasInvalidFields: false })
    }
  }

  // validates which fields need to be addressed
  validateAllFields() {
    this.setState({ hasInvalidFields: false })
    this.state.missingFields = []
    if (this.state.requestNameTaxID.recipientName === '') {
      this.state.missingFields.push('Recipient name needed.')
    }
    if (this.state.attachmentList.length === 0) {
      this.state.missingFields.push('W9 is needed.')
    }
    if (this.state.requestNameTaxID.requestersName === '') {
      this.state.missingFields.push('Requester name is needed.')
    }
    if (this.state.requestNameTaxID.phoneNumber === '') {
      this.state.missingFields.push('Telephone number is needed.')
    }
    if (this.state.requestNameTaxID.phoneNumber !== '' && this.state.requestNameTaxID.phoneNumber.length !== 14) {
      this.state.missingFields.push('Phone number in incomplete.')
    }
    if (this.state.requestNameTaxID.email !== '' && this.isValidEmail(this.state.requestNameTaxID.email) === false) {
      this.state.missingFields.push('Please enter a valid email with @ symbol.')
    }
  }

  isValidEmail(emailPassed) {
    const re = /\S+@\S+\.\S+/
    return re.test(emailPassed)
  }

  // this will create a ticket and upload any attachments if there are any
  populateTicket() {
    let postUrl = '/api/createJiraTicket/NTC'
    const formData = new FormData()
    formData.append('requestData', JSON.stringify(this.state.requestNameTaxID))
    // add files to data form
    this.state.attachmentList.map(aFile => formData.append('files', aFile))
    this.setState({ isLoading: true, loadingStatus: 'Creating request...' })
    axios({
      method: 'post',
      url: postUrl,
      data: formData,
      headers: { 'Content-Type': 'multipart/form-data' },
    })
      .then(result => {
        // 200 if there are attachments, 201 if just Jira ticket.
        if (result.status === 201 || result.status === 200) {
          this.setState({ isLoading: false, loadingStatus: '' })
          this.putTicketInState(result.data.key)
        } else {
          // error occurred that isn't a network error
          this.setState({ isLoading: false, loadingStatus: '', isError: true })
        }
      })
      .catch(error => {
        this.setState({ isLoading: false, loadingStatus: '', isError: true })
        console.error('error: ', error)
      })
  }

  extractTicketNumber(ticket) {
    const ticketRegex = /[a-zA-Z]+-([0-9]+)/
    return ticketRegex.exec(ticket)?.[1]
  }

  putTicketInState(ticketNumber) {
    this.setState({ customerTicketNumber: ticketNumber })
    window.open('/confirmationPage/0/' + this.extractTicketNumber(this.state.customerTicketNumber), '_self')
  }

  // adds attachments to list
  handleAttachments = filesToPass => {
    this.setState({ attachmentList: filesToPass })
  }

  // handle state change from the closeModal function in Error.js
  handleErrorPopup = isOpen => {
    this.setState({ isError: isOpen })
  }

  render() {
    let listOfMVs = this.state.missingFields.map((v, index) => (
      <li className='notification-bullet' key={index}>
        {v}
      </li>
    ))
    return (
      <div>
        {this.state.isError && <Error isError={this.state.isError} update={this.handleErrorPopup} />}
        {this.state.isLoading && <LoadingSpinner />}

        <br />
        <Form>
          <Heading type={'h3-light'}>Request a Name or Tax ID Change</Heading>
          {this.state.hasInvalidFields && (
            <Notification className='notification-window' type='error'>
              <h6>Please address the following issues:</h6>
              <br />
              <ul>{listOfMVs}</ul>
            </Notification>
          )}
          <br />
          <FieldGroup id='fieldgroup--differentInquiryType' disableMargins='top'>
            <div className='formBorder'>
              <div className='borderMargin'>
                <Information1099
                  additionalRecChange={this.onChangeAdditionalRecipient}
                  onChangeHandler={this.onChangeValueHandler}
                />
                <br />
                <SupportingDocs
                  attachmentsList={this.handleAttachments}
                  required
                  canComment
                  optional={'and Required W9'}
                  W9Wording={' your required W9 and'}
                  maxFileSize={'20MB'}
                  maxTotalFileSize={'400MB'}
                />
                <br />
                <ContactInfo onChangeHandler={this.onChangeValueHandler} />
                <br />
                <AdditionalComments onChangeHandler={this.onChangeValueHandler} />
                <br />
                <br />
                <Button variant='primary' size='large' onClick={this.handleSubmit} disabled={this.state.isLoading}>
                  {this.state.isLoading ? this.state.loadingStatus : 'Submit Request'}
                </Button>
              </div>
            </div>
          </FieldGroup>
        </Form>
      </div>
    )
  }
}

export default RequestNameTaxID
