import React from 'react'
import { Link, Heading, Accordion, AccordionTab, BodyText } from '@lmig/lmds-react'
import './FAQ.css'

function FAQ() {
  const faqList = [
    {
      id: 0,
      title: 'What is a Form 1099-NEC and why did I receive one?',
      desc: (
        <BodyText type='default'>
          Beginning with tax year 2020, non-employee compensation must be recorded on Form 1099-NEC. Non-employee
          compensation was formally reported in Box 7 on Form 1099-MISC.{' '}
        </BodyText>
      ),
    },
    {
      id: 1,
      title: 'Where can I find my 1099 details?',
      desc: (
        <BodyText type='default'>
          A break-down of the payments that make up the amounts on your 1099 is located on the back of the 1099. We only
          have room to include one page of information for you, however if there is more information that is not
          included on the back of your 1099 this is noted at the end of the detail trail. If you require further
          transaction detail please submit a request at the top of this page.
        </BodyText>
      ),
    },
    {
      id: 2,
      title: 'Where is my 1099?',
      desc: (
        <BodyText type='default'>
          If you are a Safeco or otherwise general agent, 1099 reporting is not required by us. Your year-end commission
          statement can be used for reference. Safeco agents can refer to this link:{' '}
          <Link href='https://agent.safeco.com/salescompreporting/pages/start.aspx' target='_blank'>
            https://agent.safeco.com/salescompreporting/pages/start.aspx.
          </Link>{' '}
          Further, if the amount you received is under the reporting threshold of $600.00, a 1099 will not be reported.
          If you have moved recently, your 1099 could have gone to your previous address. Please submit a request at the
          top of this page to update your information.
        </BodyText>
      ),
    },
    {
      id: 3,
      title: 'Why is the amount on the 1099 different from what my records show?',
      desc: (
        <BodyText type='default'>
          <span>
            If you are a broker, the actual amount you received throughout the year may be different from what is
            reported on the 1099 due to prior year policy cancelation adjustments.{' '}
          </span>{' '}
          <br /> <br />{' '}
          <span>
            If you are not a broker, the amount could be different from your records due to the timing of the payment
            issuance. We report payments as they are issued, and not as they are received. This could cause a difference
            in amounts if the payment is issued towards the end of the year. If these explanations do not answer your
            inquiry, please submit a request at the top of this page.
          </span>
        </BodyText>
      ),
    },
    {
      id: 4,
      title: 'I believe the payment showing on my 1099 is not subject to 1099 reporting, what do I do?',
      desc: (
        <BodyText type='default'>
          Please submit a request at the top of this page and include a description as to why this payment is not
          reportable.
        </BodyText>
      ),
    },
    {
      id: 5,
      title:
        'What do I do if the Recipient TIN and/or Recipient Name is incorrect, or if my company is exempt from 1099 reporting?',
      desc: (
        <BodyText type='default'>
          Please send a signed W9 showing your correct information (exempt recipients should ensure that box 4 on the
          W9, or the exemption box, is completely filled in), along with the incorrect 1099. This can be submitted at
          the top of the page. For a blank W9, please visit the{' '}
          <Link href='http://www.irs.gov/pub/irs-pdf/fw9.pdf' target='_blank'>
            IRS website
          </Link>
          .{' '}
        </BodyText>
      ),
    },
    {
      id: 6,
      title: 'I am an attorney. Why is there money reporting in Box 1 on Form 1099-NEC, or Box 10 on Form 1099-MISC?',
      desc: (
        <BodyText type='default'>
          Attorney fees must be reported as nonemployee compensation according to the IRS regulations (formally Box 7 on
          1099-MISC). Settlement payments are reported in Box 10, Gross Proceeds paid to an Attorney on Form 1099-MISC
          (formally Box 14). If the payment showing in box 1 on Form 1099-NEC is a settlement payment, please submit a
          request above.
        </BodyText>
      ),
    },
    {
      id: 7,
      title:
        'Why did I receive a 1099 from Liberty Mutual Group Inc when I do business with Helmsman Management Services?',
      desc: (
        <BodyText type='default'>
          You may have received payments from Helmsman Management Services, however receive a Liberty Mutual Group Inc
          1099. For tax reporting purposes, Helmsman reports through Liberty Mutual. All lines of business remain the
          same.
        </BodyText>
      ),
    },
    {
      id: 8,
      title: 'I received a 1099 from Liberty Mutual, but I do not do business with Liberty Mutual.',
      desc: (
        <BodyText type='default'>
          We made a payment to you from Liberty Mutual or one of our subsidiaries that is reportable income according to
          IRS guidelines. The IRS forms 1099-Misc, 1099-NEC and 1099-INT are forms the IRS requires Liberty Mutual to
          file for reportable income to non-employees. If still feel that you received this form in error, please submit
          a request at the top of this page.
        </BodyText>
      ),
    },
  ]

  return (
    <div>
      <Heading type={'h3-light'} truncate>
        Frequently Asked Questions
      </Heading>
      <Accordion as='h4' className='faq component'>
        {faqList?.map(f => (
          <AccordionTab labelVisual={f.title} key={`faq-${f.id}`}>
            {f.desc}
          </AccordionTab>
        ))}
      </Accordion>
    </div>
  )
}

export default FAQ
