import React from 'react'
import { Card, GridCol, GridRow, Heading, IconButton, IconHomepage, IconRefresh } from '@lmig/lmds-react'

function NotFound() {
  return (
    <div style={{ paddingBottom: '10px', marginTop: '40px' }}>
      <Card elevation={'raised'} title={`ERROR: Unfortunately, we've encountered an error on this page.`}>
        <GridRow>
          <GridCol base={12}>
            <GridRow>
              <GridCol base={12}>
                <Heading type='h5-bold'>Page not found.</Heading>
              </GridCol>
              <GridCol base={12}>
                <p>Please reload or click the homepage icon.</p>
              </GridCol>

              <GridCol base={12}>
                <div style={{ display: 'flex', justifyContent: 'flex-start', marginTop: '20px' }}>
                  <IconButton background={'teal'} href={window.location.href} style={{ marginRight: '40px' }}>
                    <IconRefresh label={'Reload'} size={'32'} />
                  </IconButton>
                  <IconButton background={'teal'} href={window.location.origin} style={{ marginRight: '40px' }}>
                    <IconHomepage label={'Homepage'} size={'32'} />
                  </IconButton>
                </div>
              </GridCol>
            </GridRow>
          </GridCol>
        </GridRow>
      </Card>
    </div>
  )
}

export default NotFound
