import React, { Component } from 'react'
import { Button } from '@lmig/lmds-react'
import './vocButton.css'
class VOCButton extends Component {
  constructor(props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange() {
    window.open('https://www.surveyanalytics.com/t/AJA0dZOrhR', '_blank')
  }

  render() {
    return (
      <div>
        <Button className='vocbtn' variant='secondary' onClick={this.handleChange} size='small'>
          Feedback
        </Button>
      </div>
    )
  }
}

export default VOCButton
