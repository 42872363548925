import React, { Component } from 'react'
import {Header, GridRow, GridCol, Heading, BodyText, List, ListItem, Notification} from '@lmig/lmds-react'
import FAQ from './FAQ'
import ReactGA from 'react-ga'
import RequestTypeGrid from './RequestTypeGrid'
import './LandingPage.css'
import VOCButton from './VOCButton'

class TaxPortalLandingPage extends Component {
  componentDidMount = () => {
    ReactGA.initialize('UA-187150487-1')
    let ga = ReactGA.ga()
    ga('send', 'pageview', window.location.pathname + window.location.search)
  }

  render() {
    let showError = false
    return (
      <>
        <GridRow gutters>
          <GridCol base={12} xl={6}>
            <Notification alert={"W-2s (Employees): "} highlightType="informative">
              Payroll Tax: 1-877-564-4772 (In HR Support Center); Prior employees, retirees or employees without access to
              the Liberty network, may submit their request to TalentServices@LibertyMutual.com
            </Notification>
          </GridCol>
          <GridCol base={12} xl={6}>
            <Notification alert={"1099R - Employees: "} highlightType="informative">
              Benefits: 1-800-758-4460, Non-employees, Prior employees, retirees or employees without access to the
              Liberty network, may submit their request to TalentServices@LibertyMutual.com
            </Notification>
          </GridCol>
        </GridRow>
        {showError && (
          <Header style={{ backgroundColor: '#FF5733', color: 'white' }} role='banner'>
            <h2>
              <b>Attention: </b> This application is currently undergoing maintenance please direct all inquiries to
              unclaimedproperty@libertymutual.com
            </h2>
          </Header>
        )}
        <GridRow gutters>
          <GridCol base={12} xl={6}>
            <BodyText type={'lead'}>
              The IRS requires Liberty Mutual to issue a 1099 for all reportable income to non-employees. If you have
              received a 1099 from us, records indicate we made a payment to you from Liberty Mutual or one of its
              subsidiaries. Typical payments that we issue that require a 1099 include, but are not limited to:
            </BodyText>
            <List>
              <ListItem>Payments issued to providers who performed services.</ListItem>
              <ListItem>Business income loss payments.</ListItem>
              <ListItem>Awards for punitive damages/non-physical injury.</ListItem>
              <ListItem>Ex gratia payments.</ListItem>
              <ListItem>Awards wherein our company paid attorney fees on your behalf.</ListItem>
              <ListItem>Interest earned on payments.</ListItem>
            </List>

            <Heading type={'h3-light'}>Disclaimer</Heading>
            <BodyText type={'article'}>
              Liberty Mutual <strong>does not</strong> provide legal or tax advice. The information herein is general in
              nature and should not be considered legal or tax advice. Consult an attorney or tax professional regarding
              your specific situation.
            </BodyText>
          </GridCol>
          <GridCol md={1} />
          <GridCol base={12} xl={5}>
            <div>
              <RequestTypeGrid />
            </div>
          </GridCol>
          <GridCol base={12} />
          <FAQ />
        </GridRow>
        <VOCButton />
      </>
    )
  }
}

export default TaxPortalLandingPage
