import React from 'react'
import {Route, Routes} from 'react-router-dom'
import { Content, Disclaimer, FloatingHeader, Footer, GridCol, GridRow, Heading, Link } from '@lmig/lmds-react'
import TaxPortalLandingPage from './treasuryTaxComponents/TaxPortalLandingPage'
import NotFound from './common/NotFound'
import DataForm from './treasuryTaxComponents/DataForm'
import ConfirmationPage from './treasuryTaxComponents/ConfirmationPage'
import ViewTicket from './treasuryTaxComponents/ViewTicket'
import './app.scss'

export function App() {
  const year = new Date().getUTCFullYear()
  const showError = false
  const homepage = window.location.origin + ''
  return (
    <>
      <FloatingHeader
        brand='liberty'
        appName='Treasury Tax Portal '
        isFullBackground
        brandLinkProps={{ href: homepage }}
      />
      <br />
      {showError && (
        <Heading type={'h4-light'} className='maintenance-banner' role='banner' align='center'>
          <b>Attention: </b> This application is currently undergoing maintenance please direct all inquiries to
          unclaimedproperty@libertymutual.com
        </Heading>
      )}
      <Content className={'main'} role={'main'}>
        <Routes>
          <Route exact path='/' element={<TaxPortalLandingPage />} errorElement={<NotFound />} />
          <Route path='/form/:id' element={<DataForm />} errorElement={<NotFound />} />
          <Route exact path='/confirmationPage/:id/:ticketNum' element={<ConfirmationPage />} errorElement={<NotFound />} />
          <Route exact path='/confirmationPage/:id' element={<ConfirmationPage />} errorElement={<NotFound />} />
          <Route exact path='/viewticket' element={<ViewTicket />} errorElement={<NotFound />} />
          <Route path='/*' element={<NotFound />} />
        </Routes>
      </Content>
      <Footer role='contentinfo'>
        <GridRow gutters vAlign={'bottom'}>
          <GridCol base={12} md={6}>
            <Disclaimer>
              <p>© {year} Liberty Mutual Insurance Company, 175 Berkeley Street, Boston, MA 02116</p>
            </Disclaimer>
          </GridCol>
          <GridCol base={12} md={6}>
            <div style={{ margin: '0 0 10px 0' }}>
              <Link href='https://www.libertymutualgroup.com/about-lm/our-company/privacy-policy'>Privacy Policy</Link>
              <span> | </span>
              <Link href='https://www.libertymutualgroup.com/about-lm/our-company/security-policy'>
                Security Policy
              </Link>
              <span> | </span>
              <Link href='https://www.libertymutualgroup.com/about-lm/our-company/web-accessibility-policy'>
                Web Accessibility Policy
              </Link>
            </div>
          </GridCol>
        </GridRow>
        <br />
      </Footer>
    </>
  )
}
