import React, { Component } from 'react'
import { GridRow, Heading, IconButton, IconRemoveItem } from '@lmig/lmds-react'
import './FormComponentsCSS/NamesOfPayees.css'

class NamesOfPayees extends Component {
  render() {
    const { name, index, onDelete } = this.props
    return (
      <div>
        <GridRow id='newNames'>
          <IconButton id='deleteBtn' background={'transparent'} onClick={() => onDelete(index)}>
            <IconRemoveItem id='icon' size='16' />
          </IconButton>
          <Heading type={'h5'} id='name'>
            {name}
          </Heading>
        </GridRow>
        <br />
      </div>
    )
  }
}

export default NamesOfPayees
