import React, { Component } from 'react'
import { Select, SelectOption, FieldGroup } from '@lmig/lmds-react'
import { countries } from './dropdownConstants'

class Country extends Component {
  state = {
    countries: [],
  }

  componentDidMount() {
    this.setState({
      countries: countries,
    })
  }

  render() {
    const { countries } = this.state
    const { onChangeValue } = this.props

    let countriesList =
      countries.length > 0 &&
      countries.map((item, i) => {
        return (
          <SelectOption key={i} value={item.code}>
            {item.label}
          </SelectOption>
        )
      }, this)

    return (
      <FieldGroup id='fieldgroup--country' disableMargins='top'>
        <Select
          defaultValue='United States'
          name='country'
          id='select--default'
          labelA11y='Select your country'
          labelVisual='Country'
          onChange={onChangeValue}
          placeholderOption='Country'
        >
          {countriesList}
        </Select>
      </FieldGroup>
    )
  }
}

export default Country
