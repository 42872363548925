import React, { Component } from 'react'
import { Select, SelectOption, FieldGroup } from '@lmig/lmds-react'
import { usStates } from './dropdownConstants'

class State extends Component {
  state = {
    ht: 'error',
    val: undefined,
    states: [],
  }
  handleFormChange = event => {
    let inputValue = event.target.value
    if (inputValue.length > 0) {
      this.setState({ ht: undefined })
    } else {
      this.setState({ ht: 'error' })
    }
  }

  componentDidMount() {
    this.setState({
      states: usStates,
    })
  }

  render() {
    const { states } = this.state
    const { onChangeValue } = this.props

    let statesList =
      states.length > 0 &&
      states.map((item, i) => {
        return (
          <SelectOption key={i} value={item.code}>
            {item.label}
          </SelectOption>
        )
      }, this)
    return (
      <FieldGroup
        id='fieldgroup--state'
        disableMargins='top'
        messages={[
          {
            text: 'Required',
            type: 'error',
          },
        ]}
      >
        <Select
          name='state'
          id='select--state'
          labelA11y='Select your state'
          labelVisual='State'
          highlightType={this.state.ht}
          onChange={function (e) {
            onChangeValue(e)
            this.handleFormChange(e)
          }.bind(this)}
          placeholderOption='State'
        >
          {statesList}
        </Select>
      </FieldGroup>
    )
  }
}

export default State
