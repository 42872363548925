import React, { Component } from 'react'
import { FieldGroup } from '@lmig/lmds-react'
import Attachment from '../FormComponents/Attachment'

class SupportingDocs extends Component {
  constructor(props) {
    super()
    this.optional = props.optional
    this.W9Wording = props.W9Wording
    this.required = props.required
    this.canComment = props.canComment
  }

  render() {
    return (
      <FieldGroup id='fieldgroup--supportingDocs' disableMargins='top'>
        <div>
          <h4>
            <strong>Supporting Documentation {this.props.optional}</strong>
          </h4>
          <p>
            Attach {this.props.W9Wording} any other documentation relevant to your request
            {this.canComment && '  and explain its significance in the "Additional Comments" field below'}.
          </p>
          <Attachment
            attachList={this.props.attachmentsList}
            required={this.props.required}
            maxFileSize={this.props.maxFileSize}
            maxTotalFileSize={this.props.maxTotalFileSize}
          />
        </div>
      </FieldGroup>
    )
  }
}

export default SupportingDocs
